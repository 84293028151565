import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ title, description, lang, meta, siteUrl, image, imageWidth, imageHeight }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterUsername
            siteUrl
            image
          }
        }
      }
    `
  )


  const metaDescription = description || site.siteMetadata.description
  const metaSiteUrl = siteUrl || site.siteMetadata.siteUrl
  const metaImagePost = image || `${site.siteMetadata.siteUrl}/${site.siteMetadata.image}`
  const metaTitle =  title || site.siteMetadata.title
  const imageSizeWidth =  imageWidth || "";
  const imageSizeHeight =  imageHeight || "";
  
  
  var decodeHtmlEntity = function(str) {
    return str.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={decodeHtmlEntity(title)}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: decodeHtmlEntity(metaTitle),
        },
        {
          property: `og:url`,
          content: metaSiteUrl
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImagePost,
        },
        {
          property: `og:image:width`,
          content: imageSizeWidth,
        },
        {
          property: `og:image:height`,
          content: imageSizeHeight,
        },
        {
          property: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: decodeHtmlEntity(metaTitle),
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImagePost,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
