import React from "react"
import { CreateLocalLink } from "../utils"
import UniversalLink from "./UniversalLink"

const MenuItem = ({ menuItem, wordPressUrl }) => {
  return (
    <UniversalLink className="nav__links__item"
                   to={CreateLocalLink(menuItem, wordPressUrl)} activeClassName="nav__links__item--active">
      {menuItem.label}
    </UniversalLink>
  )
}

export default MenuItem