import { Link, useStaticQuery, graphql  } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage, getImage }  from "gatsby-plugin-image"
import Menu from "./Menu"

const Header = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "marcogomesweb-logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData (
            width: 24,
            height:27
            )
        }
      }
    }
  `)

  const imageAvatar = getImage(data.file.childImageSharp )

  return (
    <header>
      <div className="container">
        <nav className="nav">
          <Link to="/" className="nav__logo">
            <GatsbyImage image={ imageAvatar} alt="marco gomes web" />
            <span className="nav__logo__text">Marco Gomes</span>
          </Link>
          <Menu />
        </nav>
      
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
