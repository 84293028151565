import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import '../sass/app.scss';

import {  useStaticQuery, graphql  } from "gatsby"
import { GatsbyImage, getImage, StaticImage }  from "gatsby-plugin-image"

// import gatsbyLogo from '../images/gatsby-logo.svg'
import CookieConsent from "react-cookie-consent";
import Search from "../components/search"


const Layout = ({ children, posts = null , engine = null }) => {

  
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "wordpress-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData (
          width: 30,
          height:30
        )
        
      }
    }
  }
`)

const imageAvatar = getImage(data.file.childImageSharp )
  
  return (
    <>
      <Header />
    
        <main>
          <div className="container">
          { posts !== null && engine !== null &&
            <Search posts={posts} engine={engine} placeholder="Search"/>
          }
            {children}
          </div>
        </main>

        <footer className="footer">
          <div className="container footer__meta">
            © {new Date().getFullYear()}, Built with
            {` `}
            <StaticImage src="../images/gatsby-logo.svg" className="footer__gastbylogo" alt="gatsby"/>
            <GatsbyImage image={ imageAvatar} alt="marco gomes web" />
          </div>          
            <CookieConsent
                location="bottom"
                containerClasses="cookie-content"
                contentClasses="cookie-content__inner"
                cookieName="gatsby-gdpr-google-analytics"
                expires={999}
              >
                This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media.
            </CookieConsent>
        </footer>      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout