import React from "react"
import ClientSearch from "../client-search";

const Search = props => {
  const {
    posts,
    engine,
    placeholder
  } = props


  return (
   
      
      <ClientSearch
        posts={posts}
        engine={engine}
        placeholder={placeholder}
      />

    
  )
}

export default Search